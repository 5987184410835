.tableContainer{
    width: 100%;
    margin: 1em 0 1em 0;
    padding-bottom: 0em;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
      }
}
.title{
    margin-top: 1em;
    margin-left: 1em;
    margin-bottom: 1em;
}
.headerGroup{
    display: inline-flex;
    align-items: center;
}

.sortArrow{
    margin-left: 5px;
}

table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

thead th {
    padding: 12px;
    font-weight: bold;
    text-align: right;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
}

tbody tr {
    border-bottom: 1px solid #ddd;
}

tbody td {
    padding: 12px;
    font-size: 14px;
    color: #616161;
}

tbody tr:hover {
    background-color: #f1f1f1;
}

.locationTableDataCell {
    text-align: right;
}
