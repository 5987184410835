@import '../../colors.scss';

.delegateSearchContainer {
  align-self: center;
}

.delegateSearch {
  width: 20em;
  margin-top: 4em;
}

.chipContainer {
  margin: 3em 0 0 0;
  align-self: center;
}

.chip {
  margin-top: .5em;
  height: 3em;
}

.saveButton {
  width: 15em;
  height: 4em;
  background: $primary-btn-dark;
  color: $white;
  margin: 0 0 1em 0;
  border-radius: 16px;

  &:hover {
    background: $navy
  }

  &:disabled {
    background: $light-gray
  }
}

.saveButtonContainer {
  align-self: center;
  margin-top: 5em;
  //margin: 5em 0 0 7em;
}

.instructionsText {
  margin-top: 1.5em;
  text-align: center;
  font-size: 1.5em;
  color: #5b6770;
}

.errorText{
  margin-top: .3em;
  text-align: center;
  font-size: 1.2em;
  color: $primary-btn-dark;
}

.MuiAutocomplete-clearIndicatorDirty {
  visibility: visible;
}

.acceptButton {
  color: $primary-btn-dark;
}
