.modalContent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.dropdown {
  width: 80%;
  max-width: 600px;
}
