@import "../../../../../colors";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1em;
  margin: 1em;
  background-color: $container;
}

.delegateContainer {
  background-color: $secondary-container;
}

.textMiddleRow {
  margin-top: 1em;
  margin-bottom: 1em;
}