.section {
    text-align: center;

    .sftp-button {
        margin: .5em;
    }

}

.admin-tabs {
    margin-top: 1em;
}