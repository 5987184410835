.masterContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 1em 0 1em 2em;
}

.leftWidgetContainer {
  display: flex;
  flex-grow: 1;
  height: 100%;
  flex-direction: column;
}

.rightWidgetContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  margin-inline: 1em;
  padding: 1em;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  padding-inline: 1em;
  margin-inline: 1em;
  align-items: center;
  justify-content: space-between;
}

.headerRightContainer {
  width: 100%;
  margin-right: 1em;
  margin-left: auto;
  text-align: right;
}

.subHeaderContainer {
  display: flex;
  flex-direction: row;
}