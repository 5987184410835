@import '../../../../colors.scss';

.heading {
  align-self: flex-start;
  margin-bottom: 20px;
  color: $primary;
}

.delegateHeading {
  align-self: flex-start;
  margin-bottom: 20px;
  color: $teal;
}