.buttonContainer {
  display: flex;
  gap: 10px;
  margin-top: 2em;
  justify-content: center;
}

.button {
  width: 100%;
}

.bigBackButton {
  width: 80%;
}

.hidden {
  visibility: hidden;
}