.header {
  display: flex;
  align-items: center;
  margin: 1em;
}

.headerText {
  margin-left: .2em;
}

.container {
  min-height: 10em;
  max-width: 30em;
  width: 100%;
  margin: 0 1em 2em 1em;
  padding: 2em 0 2em 2em;
}

.filtersBody {
  margin: 1em 0 0 1em;
}

.dropDownOptionText {
  height: 30px;
}

.filterDropDown {
  width: 13em;
}

.yearFilter {
  display: inline-block;
  position: relative;
}

.locationsFilter {
  margin-top: 1em;
}

.xButton {
  position: absolute;
  top: 51%;
  left: 70%;
}