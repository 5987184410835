@import "../../../../colors.scss";

.navLinkContainer {
    position: relative;
    margin-bottom: 2em;
    box-shadow: 0 3px 5px -3px gray;
    padding: 1.6em 1em 1em 1em;

    @media (max-width: 400px) {
        padding-top: 2em;
        display: grid;
    }
}

.navLinkItems {
    margin-inline: 1em;
    padding-inline: 1em;
    text-decoration: none;
    font-family: Apercu, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
    color: $dark-gray;
    margin-bottom: 1em;
    position: relative;

    &:hover {
        color: $primary;
        font-weight: bold;
    }

    &::after {
        content: '';
        height: 3px;
        width: 100%;
        background: $primary;
        border-radius: 25px;
        position: absolute;
        left: 0;
        bottom: -19px;
        opacity: 0;
        pointer-events: none;
        transition: all 0.4s;
    }

    &:hover::after,
    &:focus::after {
        opacity: 1;
    }
}

.active {
    color: $primary;
    font-weight: bold;

    &::after {
        content: '';
        height: 3px;
        width: 100%;
        background: $primary;
        border-radius: 25px;
        position: absolute;
        left: 0;
        bottom: -19px;
        opacity: 1;
        pointer-events: none;
        transition: all 0.4s;

    }

}