@import '../../../../colors.scss';

.tripsWidget {
    display: flex;
    flex-grow: 1;
    min-width: 10em;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 1em 2em;
    margin: 2em auto;
    margin-left: 0.5em;
}

.titleButtonContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.validatedTextContainer{
    padding: 1em;
    margin-top: 3.5em;
}

.tripCardContainer{
    display: flex;
    justify-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 1.5em;
}
@media (max-width: 1400px) {
    .tripCardContainer {
        gap: 3em 5em;
    }
}

@media (min-width: 1400px) {
    .tripCardContainer {
        gap: 3em 1.5em;
    }
}

.delegateCreateTripButton{
    background: $teal;
}
.delegateCreateTripButton:hover{
    background: $navy !important;
}

.subHeader{
    margin-left: -.5em;
}
@media (max-width: 400px) {
    .titleButtonContainer{
        flex-direction: column;
    }
}