.headerContainer{
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
    box-shadow: 0 3px 5px -3px gray; 
}

.topNavContainer{
    margin-bottom: -15px;
}

.hiddenNavBar{
    margin-bottom: 5em;
}