@import "../../colors.scss";

.tooltipWrapper {
  position: relative;

  &::after {
    content: attr(data-tooltip);
    visibility: hidden;
    background-color: $paste;
    color: $dark-gray;
    font-size: 0.9em;
    font-weight: bolder;
    white-space: nowrap;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;
    position: absolute;
    top: 100%;
    left: 1%;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover::after {
    visibility: visible;
    opacity: 1;
  }
}