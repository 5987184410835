.processedTripsContainer {
  display: flex;
  flex-direction: row;
}

.rightWidgetContainer {
  display: flex;
  margin-top: 2em;
  margin-left: 2em;
  margin-right: 2em;
  flex-direction: column;

}
