@import "colors.scss";

/* Fonts */

@font-face {
  font-family: Apercu-medium;
  src: url("assets/fonts/Apercu-medium.otf") format("opentype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: Apercu-bold;
  src: url("assets/fonts/Apercu-bold.otf") format("opentype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: Apercu-regular;
  src: url("assets/fonts/Apercu-regular.otf") format("opentype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: Open-sans;
  src: url("assets/fonts/OpenSans-Regular.ttf") format("opentype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: Roboto;
  src: url("assets/fonts/Roboto-Regular.ttf") format("opentype"); /* Safari, Android, iOS */
}

* {
  font-family: Apercu-regular;
}

html {
  height: 100%;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

body {
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

svg {
  vertical-align: baseline;
}

h1,
h2,
h3,
h4,
h5,
p {
  color: $default;
}

h1,
h2,
h3 {
  font-family: Apercu-bold;
}

label,
h4,
h5,
h6,
p,
a {
  font-family: Roboto;
}

.button {
  font-family: Apercu-bold !important;
}

button:active,
button:focus,
button:focus:active {
  background-image: none !important;
  outline: 0;
  box-shadow: none !important;
}

.card {
  border: 0.5px solid $nav-border !important;
  border-radius: 2px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(255, 255, 255, 0.3);
  border: 1px rgba(119, 136, 153, 0.92);
  background: rgba(154, 154, 154, 0.66);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $light-gray;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $default;
}

/* Tooltip */
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: $dark-gray;
}

.tooltip-inner {
  background-color: $dark-gray;
  border-radius: 1px;
}
