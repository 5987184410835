@import "../../colors.scss";

.historyContainer {

}

.subHeaderContainer {
  display: flex;
  padding-left: 3em;
  background-color: $gray1;
  margin-top: -2em;
  padding-top: 1em;
  align-items: center;
  width: 100%;
}

.subHeaderLeft {
  display: flex;
  flex-direction: column;
}

.subHeaderRight {
  width: 100%;
  padding-top: 4px;
  margin-right: 3em;
  margin-left: auto;
  text-align: right;
}

.subHeaderText {
  margin-bottom: .5em;
}

.tabContainer {
  display: flex;
  width: 100%;
  height: 100%;
}