.textContainer{
  text-align: right;
  margin-top: 1.5em;
  flex: 1;
}

.dateRowContainer{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.datePickerContainer{
  display: flex;
  gap: 2em;
  padding: 1em;
  flex: 2.5;
}