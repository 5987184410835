@import '../../colors.scss';

.tripContainer {
  background-color: $container;
  width: 55em;
  margin: 0 auto;
  border-radius: 25px;
  padding: 1em 3em;
  display: flex;
  flex-direction: column;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  padding: 3em 4em;
}

.buttonContainer {
  display: flex;
  gap: 10px;
}

.button {
  width: 100%;
}
  
.crossMarkContainer {
  cursor: pointer;
  align-self: end;
  margin-top: .5em;
  margin-right: -.5em;
}
  
.delegateBackground {
  background-color: $secondary-container;
}

.radioGroupContainer {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
