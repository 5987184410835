@import '../../../../colors.scss';

.cardContainer{
    position: relative;
    width: 20em;
    height: 5.6em;
    padding: 0;
    overflow: visible;
    background-color: $container;
}

.delegateCardContainer{
    position: relative;
    width: 20em;
    height: 5.6em;
    padding: 0;
    overflow: visible;
    background-color: $secondary-container;
}

.iconContainer{
    top: 50%;
    width: 20%;
    position: absolute;
    transform: translate(0, -50%);
    margin-left: 1.0em;
}

.textContainer{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 1em;
    height: 100%;
    width: 80%;
    left: 20%;
}

.textRow{
    display: flex;
    padding-block: 4px;
}
.unvalidated{
    color: $primary;
    padding-block: 4px;
}
.validated{
    color: $success;
    padding-block: 4px;
}
.processed{
    color: black;
    padding-block: 4px;
}
@media (max-width: 500px) {
    .cardContainer{
        width: 80%;
        margin: 1em;
    }
}
.tooltipWrapper {
    position: relative;
    display: inline-block;
}

.tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: $paste;
    color: $dark-gray;
    font-size: 0.9em;
    font-weight: bolder;
    white-space: nowrap;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 1; 
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    
}

.tooltipWrapper:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

