.thresholdsTripsContainer {
  display: flex;
  flex-direction: row;
}

.leftWidgetContainer {
  display: flex;
  flex-grow: 1;
  min-width: 28em;
  padding: 2em 0em 5em 0em;
  height: 100%;
  flex-direction: column;
  margin: 0em 3.5em 2em 3em;
}

.rightWidgetContainer {
  display: flex;
  flex-direction: column;
  min-height: 30em;
  max-width: 30em;
  width: 100%;
  margin: 1em;
  padding: 1em 3em 1em 0em;
  
}

.card {
  display: flex;
  flex-grow: 1;
  min-width: 30em;
  padding: 3em;
  height: 100%;
  flex-direction: column;
    
}

.dashboardHelpText {
    padding-block: .4em;
}
