@import '../../../../colors.scss';

.titleTextContainer{
    padding: 0.5em;
}

.unvalidatedTripText{
    color: $primary;
    display: inline;
}

.unvalidatedDelegateTripText{
    color: $teal;
    display: inline;
}