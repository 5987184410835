.footer {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  margin-top: auto;
}

.footer a {
  color: black;
  text-decoration: none;
}