@import '../../../../colors.scss';
.card {
    min-height: 10em;
    max-width: 30em;
    min-width: 25em;
    width: 100%;
    margin: 1em;
    padding: 2em 5em 1em 2em;
}

.headerPadding{
    padding-bottom: 1em;
}

.bodyPadding{
    padding-bottom: 2em;
}

.windowClosed{
    color: $dark-gray;
    font-weight: bolder;
}

.windowOpen{
    color: $primary-active-alt;
    font-weight: bolder;
}