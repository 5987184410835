@import "../../../../colors.scss";

.container {
  display: flex;
  align-items: center;
  }

  .body {
   font-size: large;
   color: $navy;
   padding: 2em;
  }

  .header{
    color: $navy;
  }

  .icon{
    margin-right: 6px;
  }