.rowContainer {
  display: flex;
  align-items: center;
  padding: 1em 0;
}

.dateText {
  flex: 1;
  text-align: right;
}

.index0Margin {
  margin-top: 1.5em;
}

.dropdownContainer {
  display: flex;
  gap: 2em;
  padding: 1em;
  flex: 2.5;
  align-items: baseline;
}