@import "../../../../../colors";

.container{
  display: flex;
  flex-grow: 1;
  min-width: 28em;
  padding: 2em 2em 1em 2em;
  height: 100%;
  flex-direction: column;
  margin: 2em 3.5em 2em 3em;
}

.header {
  display: flex;
}

.delegateHeader {
  color: $teal;
  display: inline;
}

.headerText {
  display: inline;
  padding-left: .5em;
}

.sortButton {
  display: flex;
  justify-content: end;
  margin: 2em 2em 0 0;
  cursor: pointer;
}

.icon {
  transition: transform 0.3s ease;
}

.iconUp {
  transform: rotate(180deg);
}

.noResults {
  margin-left: 1em;
}